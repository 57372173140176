<template>
  <div class="container-fluid page">
    <page-header :title="getTitle()" :show-sidebar="showSidebar()" :logo="logo" />
    <transition name="slide" mode="out-in">
      <challenge
        v-for="challenge in challenges"
        v-if="challenge.id == challenges[active_challenge_index].id"
        :key="challenge.id"
        :challenge="challenge"
        :cloudfront_endpoint="cloudfront_endpoint"
        :vl_api_url="vl_api_url"
      />
    </transition>
    <progress-slider :items="challenges.length" :active="active_challenge_index" />
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import axios from 'axios'

import Challenge from '../components/challenges/challenge.vue'
import PageHeader from '../components/page_header.vue'
import ProgressSlider from '../components/progress_slider.vue'

export default {
  watch: {
    active: function (isActive) {
      if (isActive) {
        this.startPage()
      }
    },

    reset: function (reset) {
      if (reset) {
        this.startPage()
      }
    },
  },

  props: ['templateSettings', 'id', 'active', 'gym', 'infoscreen', 'logo', 'reset', 'cloudfront_endpoint', 'vl_api_url'],

  data: function () {
    return {
      challenges: [],
      active_challenge_index: 0,
      interval: {},
      total_duration: 0,
    }
  },

  methods: {
    startPage: function () {
      this.getChallenges()
    },

    finishedPage: function () {
      this.$parent.$emit('finishedPage', this.total_duration)
    },

    getChallenges() {
      axios
        .get(`${this.vl_api_url}/challenges`)
        .then(
          response => {
            if (response.data) {
              this.challenges = response.data.filter(challenge => DateTime.fromISO(challenge.expiration_date.substring(0, 10)) > DateTime.local())
              this.active_challenge_index = 0
              this.total_duration = 0
              this.interval = setInterval(this.nextChallenge, this.templateSettings.duration * 1000)
            }
          },
          response => {
            this.$parent.$emit('finishedPage', 0)
          }
        )
        .catch(error => {
          console.error('VL: error loading challenges', error)
        })
    },

    nextChallenge: function () {
      this.active_challenge_index++
      this.total_duration += this.templateSettings.duration
      if (this.active_challenge_index >= this.challenges.length) {
        this.active_challenge_index = 0
        clearInterval(this.interval)
        this.finishedPage()
      }
    },

    getTitle: function () {
      if (this.challenges[this.active_challenge_index]) {
        let start_date = DateTime.fromISO(this.challenges[this.active_challenge_index].start_date.substring(0, 10))
        if (start_date < DateTime.local()) {
          return this.$i18n.t('infoscreens.current_challenge').toUpperCase()
        } else {
          return this.$i18n.t('infoscreens.upcoming_challenge').toUpperCase()
        }
      } else {
        return this.$i18n.t('infoscreens.challenge').toUpperCase()
      }
    },

    showSidebar: function () {
      return this.infoscreen.sidebar === 'show'
    },
  },

  components: {
    'page-header': PageHeader,
    challenge: Challenge,
    'progress-slider': ProgressSlider,
  },
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
.slide-enter {
  transform: translateX(100%);
}
.slide-leave-active {
  transform: translateX(-100%);
}
</style>
