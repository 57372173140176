<template>
  <div class="special-features col-12">
    <h3 class="type-label">{{ $t('infoscreens.special_features').toUpperCase() }}</h3>
    <div class="row">
      <div v-if="gym.autobelay" class="col-3 text-center">
        <img class="pb-2" src="@scg/../images/gym/autobelay.png" alt="" />
        <p class="type-display-8">{{ $t('infoscreens.auto_belay').toUpperCase() }}</p>
      </div>
      <div v-if="gym.bar" class="col-3 text-center">
        <img class="pb-2" src="@scg/../images/gym/bar.png" alt="" />
        <p class="type-display-8">{{ $t('infoscreens.bar').toUpperCase() }}</p>
      </div>
      <div v-if="gym.courses" class="col-3 text-center">
        <img class="pb-2" src="@scg/../images/gym/courses.png" alt="" />
        <p class="type-display-8">{{ $t('infoscreens.courses').toUpperCase() }}</p>
      </div>
      <div v-if="gym.fitness" class="col-3 text-center">
        <img class="pb-2" src="@scg/../images/gym/fitness.png" alt="" />
        <p class="type-display-8">{{ $t('infoscreens.fitness').toUpperCase() }}</p>
      </div>
      <div v-if="gym.parking" class="col-3 text-center">
        <img class="pb-2" src="@scg/../images/gym/parking.png" alt="" />
        <p class="type-display-8">{{ $t('infoscreens.parking').toUpperCase() }}</p>
      </div>
      <div v-if="gym.rent" class="col-3 text-center">
        <img class="pb-2" src="@scg/../images/gym/rent.png" alt="" />
        <p class="type-display-8">{{ $t('infoscreens.rent').toUpperCase() }}</p>
      </div>
      <div v-if="gym.restaurant" class="col-3 text-center">
        <img class="pb-2" src="@scg/../images/gym/restaurant.png" alt="" />
        <p class="type-display-8">{{ $t('infoscreens.restaurant').toUpperCase() }}</p>
      </div>
      <div v-if="gym.sauna" class="col-3 text-center">
        <img class="pb-2" src="@scg/../images/gym/sauna.png" alt="" />
        <p class="type-display-8">{{ $t('infoscreens.sauna').toUpperCase() }}</p>
      </div>
      <div v-if="gym.shop" class="col-3 text-center">
        <img class="pb-2" src="@scg/../images/gym/shop.png" alt="" />
        <p class="type-display-8">{{ $t('infoscreens.shop').toUpperCase() }}</p>
      </div>
      <div v-if="gym.yoga" class="col-3 text-center">
        <img class="pb-2" src="@scg/../images/gym/yogi.png" alt="" />
        <p class="type-display-8">{{ $t('infoscreens.yoga').toUpperCase() }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['gym'],
}
</script>
