<template>
  <div class="container-fluid page">
    <page-header :title="$t('infoscreens.gym_information').toUpperCase()" :show-sidebar="infoscreen.sidebar === 'show'" :logo="logo" />
    <div class="row">
      <div class="col-5">
        <div class="col gym-cover mb-4">
          <img :src="cover" alt="" />
        </div>
      </div>
      <div class="col-7">
        <div v-if="templateSettings.gym_extra_info" class="gym-information mb-5">
          <h2 class="type-label">{{ $t('infoscreens.information').toUpperCase() }}</h2>
          <p class="type-display-1">{{ templateSettings.gym_extra_info }}</p>
        </div>
        <div v-else class="time-information mb-5">
          <h2 class="type-label">{{ $t('infoscreens.time_and_date').toUpperCase() }}</h2>
          <p class="type-display-1">{{ getDateFormatted() }}</p>
          <p class="type-display-1">{{ getTimeFormatted() }}</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <prices
          v-if="templateSettings.show_prices"
          :title="templateSettings.prices_title"
          :items="templateSettings.items"
          :currency="templateSettings.currency"
        >
        </prices>
        <special-features v-else :gym="gym"> </special-features>
      </div>
      <div class="col-7">
        <opening-hours :gym="gym" />
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import Prices from '../components/gym/prices.vue'
import LocalisationHelper from '@scg/../mixins/localisationHelper'
import SpecialFeatures from '../components/gym/special_features.vue'
import OpeningHours from '../components/gym/opening_hours.vue'
import PageHeader from '../components/page_header.vue'

export default {
  mixins: [LocalisationHelper],

  watch: {
    active: function (isActive) {
      if (isActive) {
        this.startPage()
      }
    },

    reset: function (reset) {
      if (reset) {
        this.startPage()
      }
    },
  },

  props: ['templateSettings', 'id', 'active', 'gym', 'infoscreen', 'logo', 'cover', 'reset'],

  data: function () {
    return {
      interval: {},
      time_and_date: DateTime.local(),
    }
  },

  methods: {
    startPage: function () {
      this.interval = setInterval(this.updateTimeAndDate, 1000)
      setTimeout(this.finishedPage, this.templateSettings.duration * 1000)
    },

    finishedPage: function () {
      clearInterval(this.interval)
      this.$parent.$emit('finishedPage', this.templateSettings.duration)
    },

    updateTimeAndDate: function () {
      this.time_and_date = DateTime.local()
    },

    getDateFormatted: function () {
      return this.formatDate(this.gym.settings, this.time_and_date)
    },

    getTimeFormatted: function () {
      return this.formatTime(this.gym.settings, this.time_and_date)
    },
  },

  components: {
    prices: Prices,
    'opening-hours': OpeningHours,
    'page-header': PageHeader,
    'special-features': SpecialFeatures,
  },
}
</script>
