<template>
  <div class="row pl-4 routes-overview">
    <div class="col-4 routes-summary">
      <div class="d-flex">
        <div>
          <span class="type-display-big-1">{{ routes_count }}</span>
          <br />
          <span class="type-display-6">{{ $t('routesetting.routes').toUpperCase() }}</span>
        </div>
        <template v-if="new_routes_count > 0">
          <div class="count-divider"></div>
          <span class="type-display-big-1 new-routes">{{ new_routes_count }}<img class="new-badge" src="@core/../images/new_badge.png" /></span>
        </template>
      </div>
    </div>
    <div class="col-12">
      <div class="row justify-content-center">
        <div :class="[showSidebar ? 'col-11' : 'col-8']">
          <canvas :id="'routes-chart-' + _uid"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  created: function () {
    this.getGradesRoutes()
    this.getNumberOfRoutes()
    this.getNumberOfNewRoutes()
  },

  props: ['gym', 'showSidebar', 'color'],

  data: function () {
    return {
      grades_routes: [],
      grades_new_routes: [],
      routes_count: 0,
      new_routes_count: 0,
    }
  },

  methods: {
    getGradesRoutes: function () {
      Vue.smcb_axios
        .get('/' + this.gym.slug + '/infoscreens/grades_routes')
        .then(response => {
          if (response.data) {
            this.grades_routes = response.data
            this.drawChart()
          }
        })
        .catch(error => {
          console.error('VL: error loading grades routes', error)
        })
    },

    getNumberOfRoutes: function () {
      Vue.smcb_axios
        .get('/' + this.gym.slug + '/infoscreens/routes_count')
        .then(response => {
          if (response.data) {
            this.routes_count = response.data.count
          }
        })
        .catch(error => {
          console.error('VL: error loading number of routes', error)
        })
    },

    getNumberOfNewRoutes: function () {
      Vue.smcb_axios
        .get('/' + this.gym.slug + '/infoscreens/new_routes_count')
        .then(response => {
          if (response.data) {
            this.new_routes_count = response.data.count
          }
        })
        .catch(error => {
          console.error('VL: error loading new routes count', error)
        })
    },

    drawChart: function () {
      let ctxt = $('#routes-chart-' + this._uid)
        .get(0)
        .getContext('2d')
      var myChart = new Chart(ctxt, {
        type: 'bar',
        data: {
          datasets: [
            {
              data: this.grades_routes.map(a => a.count),
              backgroundColor: this.color,
              datalabels: {
                anchor: 'end',
                align: 'top',
                font: {
                  size: 20,
                },
              },
            },
          ],

          labels: this.grades_routes.map(a => a.difficulty),
        },

        options: {
          layout: {
            padding: {
              top: 30,
            },
          },

          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkip: false,
                  fontFamily: 'Glober',
                  fontSize: 20,
                },

                gridLines: {
                  display: false,
                  drawBorder: false,
                },
              },
            ],

            yAxes: [
              {
                display: false,
                gridLines: {
                  display: false,
                },

                ticks: {
                  min: 0,
                },
              },
            ],
          },

          responsive: true,
          legend: {
            display: false,
          },
        },
      })
    },
  },
}
</script>
