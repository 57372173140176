<template>
  <div class="row pt-5 mt-5">
    <route-cell v-if="type === 'GymRoute'" :route="zlaggable" :cloudfront_endpoint="cloudfront_endpoint" />
    <boulder-cell v-if="type === 'GymBoulder'" :boulder="zlaggable" :cloudfront_endpoint="cloudfront_endpoint" />
    <zlaggable-details :zlaggable="zlaggable" :ascents="ascents" :gym="gym" :type="type" />
  </div>
</template>

<script>
import Vue from 'vue'

import BoulderCell from './topos_view/boulder_cell.vue'
import RouteCell from './topos_view/route_cell.vue'
import ZlaggableDetails from './zlaggable_details.vue'

export default {
  created: function () {
    this.getAscents()
  },

  props: ['gym', 'zlaggable', 'type', 'cloudfront_endpoint'],

  data: function () {
    return {
      ascents: '',
    }
  },

  methods: {
    getAscents: function (id) {
      Vue.smcb_axios
        .get('/' + this.gym.slug + '/infoscreens/ascents/' + this.type + '/' + this.zlaggable.id)
        .then(response => {
          if (response.data) {
            this.ascents = response.data
          }
        })
        .catch(error => {
          console.error('VL: error loading ascents', error)
        })
    },
  },

  components: {
    'zlaggable-details': ZlaggableDetails,
    RouteCell,
    BoulderCell,
  },
}
</script>
