<template>
  <div class="prices col-12">
    <h3 class="type-label">{{ pricesTitle }}</h3>
    <table class="prices-table">
      <tr v-for="item in items" :key="item.name">
        <td class="item-name">{{ item.name }}</td>
        <td class="item-price">{{ item.price }} {{ currency }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ['title', 'items', 'currency'],

  computed: {
    pricesTitle() {
      return this.title !== null && this.title !== '' ? this.title : this.$i18n.t('infoscreens.prices').toUpperCase()
    },
  },
}
</script>
