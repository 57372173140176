<template>
  <td class="topo-cell">
    <svg :id="'boulder-cell-' + boulder.id" :width="image_width" :height="image_height + vertical_adjustment * 2">
      <rect x="0" y="0" :width="image_width" :height="image_height + vertical_adjustment * 2" fill="#fff" />
      <image x="0" :y="vertical_adjustment" :width="image_width" :height="image_height" :xlink:href="fullImageUrl" opacity="0.8" />
      <template>
        <polygon
          :key="boulder.id"
          :points="getPointsForPath(boulder.path)"
          fill="#f0c147"
          fill-opacity="0.1"
          stroke-width="3"
          stroke="#f0c147"
          style="cursor: pointer"
        />
      </template>
    </svg>
  </td>
</template>

<script>
export default {
  created() {
    this.img = new Image()
    let self = this
    this.img.onload = function () {
      self.original_image_width = this.width
      self.original_image_height = this.height
    }
    this.img.src = this.fullImageUrl
    if (window.innerWidth > 1400) this.scaling_factor = 0.5
  },

  props: {
    cloudfront_endpoint: String,
    boulder: Object,
  },

  data: function () {
    return {
      original_image_width: 0,
      original_image_height: 0,
      scaling_factor: 0.4,
      vertical_adjustment: 20,
      boulders_indication_baseline: 190,
      img: null,
    }
  },

  computed: {
    fullImageUrl() {
      return `${this.cloudfront_endpoint}${this.boulder.image_path}.jpg`
    },

    image_width() {
      return this.original_image_width * this.scaling_factor
    },

    image_height() {
      return this.original_image_height * this.scaling_factor
    },
  },

  methods: {
    getPointsForPath(path) {
      path = JSON.parse(path).map(point => {
        return {
          x: point[0],
          y: point[1],
        }
      })
      let points = ''
      path.forEach(point => {
        points += '' + point.x * this.scaling_factor + ',' + (point.y * this.scaling_factor + this.vertical_adjustment) + ' '
      })
      return points
    },
  },
}
</script>

<style scoped>
.boulder-circle:hover {
  cursor: pointer;
}
</style>
