<template>
  <div v-if="items > 1" class="progress-slider">
    <div v-for="index in items" :key="index - 1" class="p-1 slide-item" :class="[index - 1 == active ? 'slide-item-active' : '']"></div>
  </div>
</template>

<script>
export default {
  props: ['items', 'active'],

  methods: {
    isActivePage() {
      if (this.activePage) {
        return this.activePage.id == this.page.id
      } else {
        return false
      }
    },

    getPageTitle() {
      return this.page.template.replace('_', ' ').toUpperCase()
    },
  },
}
</script>
