<template>
  <div class="row page-header pl-4 mb-2">
    <h2 class="col-8 header-title pl-1">{{ title }}</h2>
    <div class="col-4" :class="[showSidebar ? 'header-badge' : 'page-header-badge']">
      <img v-if="!showSidebar" class="gym-logo mr-5" :src="logo" alt="" />
      <img src="@scg/../images/vl-app-badge.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'showSidebar', 'logo'],
}
</script>
