<template>
  <div class="col-12 col-md-4 col-xl-3 pl-4 sidebar">
    <div class="gym-logo">
      <img :src="logo" alt="" />
    </div>
    <h1 class="gym-name type-display-1">{{ gym.name }}</h1>
    <ul class="menu">
      <nav-item v-for="page in pages" :key="page.id" :page="page" :active-page="activePage" />
    </ul>
    <div v-if="sponsor_image" class="sponsor pl-4 pr-4">
      <img :src="sponsor_image" alt="" />
    </div>
  </div>
</template>

<script>
import NavItem from './nav_item.vue'
export default {
  props: ['pages', 'activePage', 'gym', 'logo', 'sponsor_image'],

  components: {
    'nav-item': NavItem,
  },
}
</script>
