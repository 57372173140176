<template>
  <div>
    <page-header :title="challenge.title" :show-sidebar="infoscreen.sidebar === 'show'" :logo="logo" />
    <table :id="table_id" class="ranking-table">
      <thead>
        <tr class="ranking-table-header">
          <th>{{ $t('infoscreens.rank').toUpperCase() }}</th>
          <th>{{ $t('infoscreens.name').toUpperCase() }}</th>
          <th>{{ scoring_header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in ranking" :key="user.id">
          <td>{{ user.rank }}</td>
          <td>
            <span :class="[index === 0 ? 'king' : '']"><img :src="avatar_url(user)" width="64" height="64" /></span>{{ user.name }}
          </td>
          <td>{{ user.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import PageHeader from '../page_header.vue'

export default {
  watch: {
    active: {
      immediate: true,

      handler(val) {
        if (val === true) this.start_ranking()
      },
    },
  },

  props: {
    infoscreen: {
      type: Object,
      required: true,
    },

    active: Boolean,
    logo: {
      type: String,
      required: true,
    },

    challenge: {
      type: Object,
      required: true,
    },

    // eslint-disable-next-line vue/prop-name-casing
    templateSettings: {
      type: Object,
      required: true,
    },

    ranking: {
      type: Array,
      default: () => [],
    },
  },

  data: function () {
    return {
      total_duration: 0,
      scroll_counter: 1,
      scroll_pages: 0,
      scroll_interval: 0,
      interval: null,
    }
  },

  computed: {
    table_id() {
      return `challenge-${this.challenge.id}-ranking`
    },

    scoring_header() {
      return this.$t(`challenges.types.${this.challenge.value_unit}.name`)
    },
  },

  methods: {
    start_ranking() {
      $(`#${this.table_id} tbody`).animate({ scrollTop: 0 }, 100)
      this.scroll_pages = Math.ceil(this.ranking.length / 9)
      this.scroll_interval = this.templateSettings.duration
      this.interval = setInterval(this.scroll_results, this.scroll_interval * 1000)
    },

    scroll_results() {
      $(`#${this.table_id} tbody`).animate({ scrollTop: 693 * this.scroll_counter }, 900)
      this.scroll_counter += 1
      if (this.scroll_counter >= this.scroll_pages + 1) {
        clearInterval(this.interval)
        this.total_duration = this.scroll_pages * this.scroll_interval
        this.finished_page()
      }
    },

    finished_page() {
      this.scroll_counter = 1
      this.scroll_pages = 0
      let duration = this.total_duration
      this.total_duration = 0
      $(`#${this.table_id} tbody`).animate({ scrollTop: 0 }, 100)
      this.$emit('finished_ranking', duration)
    },

    avatar_url(user) {
      if (user.avatar) {
        return `https://d1ffqbcmevre4q.cloudfront.net/${user.avatar}`
      }
      return 'https://d1ffqbcmevre4q.cloudfront.net/avatar_default_female_1.jpg'
    },
  },

  components: {
    'page-header': PageHeader,
  },
}
</script>
