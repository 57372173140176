<template>
  <div class="col-7 zlaggable-details">
    <div class="row">
      <div class="col-5">
        <p class="type-display-4 mb-1">{{ getFormattedDate(zlaggable.set_at) }}</p>
        <p class="type-display-3 mb-1">{{ zlaggable.name }}</p>
        <p class="type-display-4 mb-4">{{ getZlaggableLocation() }}</p>
        <grade-circle :grade="zlaggable.difficulty" :color="zlaggable.color_1" />
        <h1 class="type-display-6 mb-1 label">{{ $t('routesetting.sector').toUpperCase() }}</h1>
        <h2 class="type-display-3 mb-3">{{ zlaggable.wall_name }}</h2>
        <template v-if="zlaggable.display_route_setter">
          <h1 class="type-display-6 mb-1 label">{{ $t('routesetting.route_setter').toUpperCase() }}</h1>
          <h2 class="type-display-3 mb-3">{{ zlaggable.routesetter_name }} {{ zlaggable.routesetter_surname }}</h2>
        </template>
      </div>
      <div class="col-7 pr-5">
        <zlaggable-style :zlaggable="zlaggable" />
        <route-ascents v-if="type === 'GymRoute'" :ascents="ascents" />
        <boulder-ascents v-else :ascents="ascents" />
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import GradeCircle from './grade_circle.vue'
import ZlaggableStyle from './zlaggable_style.vue'
import RouteAscents from './charts/route_ascents.vue'
import BoulderAscents from './charts/boulder_ascents.vue'
import LocalisationHelper from '@scg/../mixins/localisationHelper'

export default {
  mixins: [LocalisationHelper],
  created: function () {},
  props: ['gym', 'zlaggable', 'ascents', 'type'],

  methods: {
    getZlaggableLocation: function () {
      if (this.type === 'GymRoute') {
        return this.zlaggable.line_nr + '.' + this.zlaggable.number
      } else {
        if (this.zlaggable.zone_prefix) {
          return this.zlaggable.zone_prefix + '.' + this.zlaggable.number
        } else {
          return this.zlaggable.zone_nr + '.' + this.zlaggable.number
        }
      }
    },

    getFormattedDate: function (date) {
      return this.formatDate(this.gym.settings, DateTime.fromJSDate(new Date(date)))
    },
  },

  components: {
    'grade-circle': GradeCircle,
    'zlaggable-style': ZlaggableStyle,
    'route-ascents': RouteAscents,
    'boulder-ascents': BoulderAscents,
  },
}
</script>
