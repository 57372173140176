<template>
  <div class="grade-circle mb-4" :style="getStyleObject()">
    <h1 class="type-display-2" :class="{ smaller: grade.length > 7 }">{{ grade }}</h1>
  </div>
</template>

<script>
export default {
  props: ['grade', 'color'],

  methods: {
    getStyleObject: function () {
      return {
        border: `8px solid ${this.color}`,
      }
    },
  },
}
</script>

<style scoped>
.smaller {
  font-size: 75%;
}
</style>
