<template>
  <li v-if="page.template !== 'promotion'" class="menu-item" :class="[is_active_page ? 'active' : '']">
    <img v-if="page.template === 'boulders' && is_active_page" src="@scg/../images/nav/boulders_white.png" alt="" />
    <img v-if="page.template === 'challenges' && is_active_page" src="@scg/../images/nav/challenges_white.png" alt="" />
    <img v-if="page.template === 'gym_challenges' && is_active_page" src="@scg/../images/nav/rankings_white.png" alt="" />
    <img v-if="page.template === 'gym_info' && is_active_page" src="@scg/../images/nav/gym_info_white.png" alt="" />
    <img v-if="page.template === 'rankings' && is_active_page" src="@scg/../images/nav/rankings_white.png" alt="" />
    <img v-if="page.template === 'routes' && is_active_page" src="@scg/../images/nav/routes_white.png" alt="" />
    <img v-if="page.template === 'zlagfeed' && is_active_page" src="@scg/../images/nav/zlagfeed_white.png" alt="" />
    <img v-if="page.template === 'boulders' && !is_active_page" src="@scg/../images/nav/boulders.png" alt="" />
    <img v-if="page.template === 'challenges' && !is_active_page" src="@scg/../images/nav/challenges.png" alt="" />
    <img v-if="page.template === 'gym_challenges' && !is_active_page" src="@scg/../images/nav/rankings.png" alt="" />
    <img v-if="page.template === 'gym_info' && !is_active_page" src="@scg/../images/nav/gym_info.png" alt="" />
    <img v-if="page.template === 'rankings' && !is_active_page" src="@scg/../images/nav/rankings.png" alt="" />
    <img v-if="page.template === 'routes' && !is_active_page" src="@scg/../images/nav/routes.png" alt="" />
    <img v-if="page.template === 'zlagfeed' && !is_active_page" src="@scg/../images/nav/zlagfeed.png" alt="" />
    {{ $t(`infoscreens.templates.${page.template}.title_short`).toUpperCase() }}
  </li>
</template>

<script>
export default {
  props: ['page', 'activePage', 'image', 'theme'],

  computed: {
    is_active_page() {
      if (this.activePage) {
        return this.activePage.id === this.page.id
      } else {
        return false
      }
    },
  },
}
</script>
