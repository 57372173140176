<template>
  <div class="container-fluid page">
    <template v-for="index in challenges.length">
      <gym-challenge
        v-if="index - 1 === active_challenge_index && challenge_rankings"
        :key="index"
        :active="true"
        :challenge="challenges[index - 1]"
        :ranking="challenge_rankings[challenges[index - 1].id]"
        :infoscreen="infoscreen"
        :logo="logo"
        :template-settings="templateSettings"
        @finished_ranking="duration => finished_ranking(duration)"
      />
    </template>
  </div>
</template>

<script>
import GymChallenge from '../components/gym_challenges/gym_challenge.vue'

export default {
  watch: {
    active(val) {
      if (val) this.start_page()
    },

    reset(val) {
      if (val) this.start_page()
    },
  },

  props: {
    // eslint-disable-next-line vue/prop-name-casing
    templateSettings: {
      type: Object,
      required: true,
    },

    active: Boolean,
    reset: Boolean,
    infoscreen: {
      type: Object,
      required: true,
    },

    logo: {
      type: String,
      required: true,
    },
  },

  data: function () {
    return {
      total_duration: 0,
      active_challenge_index: 0,
      challenge_rankings: null,
    }
  },

  computed: {
    challenges() {
      return this.use.infoscreens.challenges.filter(c => this.templateSettings.selected_challenges.includes(c.id))
    },
  },

  methods: {
    async start_page() {
      this.challenge_rankings = await this.use.infoscreens.fetch_gym_challenges_rankings(this.templateSettings.selected_challenges)
      this.total_duration = 0
      this.active_challenge_index = 0
    },

    finished_ranking(duration) {
      this.total_duration += duration
      this.active_challenge_index++
      if (this.active_challenge_index >= this.challenges.length) {
        this.challenge_rankings = null
        this.$parent.$emit('finishedPage', this.total_duration)
      }
    },
  },

  components: {
    GymChallenge,
  },
}
</script>
