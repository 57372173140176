<template>
  <div class="container-fluid page">
    <transition name="fade" mode="out-in">
      <user-ranking
        v-if="templateSettings.routes_ranking.enable"
        v-show="show_routes_ranking"
        :settings="templateSettings.routes_ranking"
        :template-settings="templateSettings"
        :gym="gym"
        :logo="logo"
        :infoscreen="infoscreen"
        :active="show_routes_ranking"
        zlaggable-type="GymRoute"
      />
    </transition>
    <transition name="fade" mode="out-in">
      <user-ranking
        v-if="templateSettings.boulders_ranking.enable"
        v-show="show_boulders_ranking"
        :settings="templateSettings.boulders_ranking"
        :template-settings="templateSettings"
        :gym="gym"
        :logo="logo"
        :infoscreen="infoscreen"
        :active="show_boulders_ranking"
        zlaggable-type="GymBoulder"
      />
    </transition>
  </div>
</template>

<script>
import UserRanking from '../components/ranking/user_ranking.vue'

export default {
  mounted() {
    this.$on('finishedRanking', function (zlaggableType, duration) {
      this.total_duration += duration
      if (zlaggableType == 'GymRoute') {
        this.show_routes_ranking = false
        if (this.templateSettings.boulders_ranking.enable) {
          this.show_boulders_ranking = true
        } else {
          this.$parent.$emit('finishedPage', this.total_duration)
        }
      } else {
        this.show_boulders_ranking = false
        this.$parent.$emit('finishedPage', this.total_duration)
      }
    })
  },

  watch: {
    active: function (isActive) {
      if (isActive) {
        this.startPage()
      }
    },

    reset: function (reset) {
      if (reset) {
        this.startPage()
      }
    },
  },

  props: ['templateSettings', 'id', 'active', 'gym', 'infoscreen', 'logo', 'reset'],

  data: function () {
    return {
      show_routes_ranking: false,
      show_boulders_ranking: false,
      total_duration: 0,
    }
  },

  methods: {
    startPage: function () {
      this.total_duration = 0
      if (this.templateSettings.routes_ranking.enable) {
        this.show_routes_ranking = true
      } else if (this.templateSettings.boulders_ranking.enable) {
        this.show_boulders_ranking = true
      } else {
        this.$parent.$emit('finishedPage', this.total_duration)
      }
    },
  },

  components: {
    'user-ranking': UserRanking,
  },
}
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
