<template>
  <div class="container-fluid page">
    <page-header :title="getTitle()" :show-sidebar="infoscreen.sidebar === 'show'" :logo="logo" />
    <transition name="slide" mode="out-in">
      <boulders-chart v-if="active_item == 0" :gym="gym" :show-sidebar="infoscreen.sidebar === 'show'" :color="color" />
      <zlaggable-info
        v-for="boulder in latest_boulders"
        v-if="latest_boulders[active_item - 1] && latest_boulders[active_item - 1].id == boulder.id"
        :key="boulder.id"
        :zlaggable="boulder"
        :gym="gym"
        :cloudfront_endpoint="cloudfront_endpoint"
        type="GymBoulder"
      />
    </transition>
    <progress-slider :items="items" :active="active_item" :theme="infoscreen.theme" />
  </div>
</template>

<script>
import Vue from 'vue'

import BouldersChart from '../components/zlaggable/charts/boulders.vue'
import PageHeader from '../components/page_header.vue'
import ProgressSlider from '../components/progress_slider.vue'
import ZlaggableInfo from '../components/zlaggable/zlaggable_info.vue'
export default {
  created: function () {
    this.resetPage()
  },

  watch: {
    active: function (isActive) {
      if (isActive) {
        this.active_item = 0
        this.startPage()
      }
    },

    reset: function (reset) {
      if (reset) {
        this.active_item = 0
        this.startPage()
      }
    },
  },

  props: ['templateSettings', 'id', 'active', 'gym', 'infoscreen', 'logo', 'reset', 'color', 'cloudfront_endpoint'],

  data: function () {
    return {
      items: 1,
      active_item: 0,
      latest_boulders: [],
      slide_duration: 10,
    }
  },

  methods: {
    getLatestBoulders: function () {
      Vue.smcb_axios
        .get('/' + this.gym.slug + '/infoscreens/latest_boulders')
        .then(response => {
          if (response.data) {
            this.latest_boulders = response.data
            this.items += this.latest_boulders.length
          }
        })
        .catch(error => {
          console.error('VL: error loading latest boulders', error)
        })
    },

    startPage: function () {
      setTimeout(this.nextPage, this.slide_duration * 1000)
    },

    nextPage: function () {
      if (this.active_item < this.items - 1) {
        this.active_item += 1
        this.startPage()
      } else {
        this.finishedPage()
      }
    },

    finishedPage: function () {
      this.$parent.$emit('finishedPage', this.slide_duration * this.items)
      this.resetPage()
    },

    resetPage: function () {
      this.active_item = 0
      this.items = 1
      this.getLatestBoulders()
    },

    getTitle: function () {
      return this.active_item == 0 ? this.$i18n.t('infoscreens.our_boulders').toUpperCase() : this.$i18n.t('infoscreens.newest_boulders').toUpperCase()
    },
  },

  components: {
    'boulders-chart': BouldersChart,
    'zlaggable-info': ZlaggableInfo,
    'progress-slider': ProgressSlider,
    'page-header': PageHeader,
  },
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
.slide-enter {
  transform: translateX(100%);
}
.slide-leave-active {
  transform: translateX(-100%);
}
</style>
