<template>
  <div class="ascent-chart-container">
    <canvas id="route-ascent-chart"> </canvas>
  </div>
</template>

<script>
export default {
  mounted: function () {
    this.generateChart()
  },

  watch: {
    ascents: function (ascents) {
      if (ascents) {
        this.generateChart()
      }
    },
  },

  props: ['ascents'],

  computed: {
    max_ascents() {
      let max = Math.max(this.ascents.os || 0, this.ascents.f || 0, this.ascents.rp || 0, this.ascents.go || 0, this.ascents.tr || 0)
      return max > 0 ? max : 10
    },
  },

  methods: {
    generateChart: function () {
      let ctxt = $('#route-ascent-chart').get(0).getContext('2d')
      var myChart = new Chart(ctxt, {
        type: 'bar',
        data: {
          datasets: [
            {
              data: [this.ascents.os || 0, this.ascents.f || 0, this.ascents.rp || 0, this.ascents.go || 0, this.ascents.tr || 0],
              backgroundColor: [
                'rgba(54, 172, 156, 1.0)',
                'rgba(249, 167, 43, 1.0)',
                'rgba(218, 17, 89, 1.0)',
                'rgba(68, 79, 157, 1.0)',
                'rgba(101, 98, 99, 1.0)',
              ],

              datalabels: {
                display: false,
              },
            },
            {
              data: [this.max_ascents, this.max_ascents, this.max_ascents, this.max_ascents, this.max_ascents],
              backgroundColor: [
                'rgba(224, 224, 224, .5)',
                'rgba(224, 224, 224, .5)',
                'rgba(224, 224, 224, .5)',
                'rgba(224, 224, 224, .5)',
                'rgba(224, 224, 224, .5)',
              ],

              datalabels: {
                display: false,
              },
            },
          ],

          labels: ['ONSIGHT', 'FLASH', 'REDPOINT', 'GO', 'TOPROPE'],
        },

        options: {
          scales: {
            xAxes: [
              {
                categoryPercentage: 0.7,
                stacked: true,
                ticks: {
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90,
                  fontFamily: 'Glober',
                  fontSize: 18,
                },

                gridLines: {
                  display: false,
                  drawBorder: false,
                },
              },
            ],

            yAxes: [
              {
                display: false,
                gridLines: {
                  display: false,
                },

                ticks: {
                  min: 0,
                },
              },
            ],
          },

          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
        },
      })
    },
  },
}
</script>
