<template>
  <div id="container" class="container-fluid" :class="{ video: showGymVideo, image: activePage && activePage.template == 'promotion' }">
    <div class="row">
      <navigation v-if="showSidebar()" :pages="pages" :active-page="activePage" :gym="gym" :logo="gym_logo_path" :sponsor_image="sponsor_image_path" />
      <div
        id="page-container"
        :class="[showSidebar() ? 'col-12 col-md-8 col-xl-9 pr-4 content' : 'col-12 pl-4 pr-4 content', !showPadding() ? 'p-0 no-padding' : '']"
      >
        <transition v-for="page in pages" :key="page.id" name="fade">
          <component
            :is="page.template"
            v-show="isActive(page.id)"
            :id="page.id"
            :template-settings="page.template_settings"
            :active="isActive(page.id)"
            :gym="gym"
            :logo="gym_logo_path"
            :cover="gym_cover_path"
            :infoscreen="infoscreen"
            :color="theme_color"
            :cloudfront_endpoint="cloudfront_endpoint"
            :vl_api_url="vl_api_url"
            :image="page.image"
            :reset="resetSinglePage"
          />
        </transition>
        <div v-show="showGymVideo">
          <video
            v-if="!checkVideoAvailability"
            :key="checkVideoAvailability"
            ref="videoPlayer"
            preload="true"
            muted="true"
            class="infoscreen-video"
            @ended="onPromoVideoEnd()"
          >
            <source :src="promoVideoUrl" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import Boulders from './pages/boulders.vue'
import Challenges from './pages/challenges.vue'
import GymChallenges from './pages/gym_challenges.vue'
import GymInfo from './pages/gym_info.vue'
import Navigation from './components/navigation.vue'
import Promotion from './pages/promotion.vue'
import Rankings from './pages/rankings.vue'
import Routes from './pages/routes.vue'
import Zlagfeed from './pages/zlagfeed.vue'

export default {
  async mounted() {
    this.use.infoscreens.gym = this.gym
    this.use.infoscreens.challenges = this.gym_challenges
    this.$on('finishedPage', async time_elapsed => {
      this.totalGymPromoTime += parseInt(time_elapsed)
      const time_to_show_gym_promo = this.totalGymPromoTime >= this.infoscreen.show_promo_video_every * 60
      const is_last_page = this.activePageIndex == this.pages.length - 1
      const is_available = this.checkVideoAvailability ? await this.promoVideoAvailable() : true

      if (this.promoVideoPresent && time_to_show_gym_promo && is_last_page && is_available) {
        this.showGymPromoVideo()
      } else {
        this.nextPage()
      }
    })

    if (this.infoscreen.show_promo_video_every != null && this.promo_video != null) {
      this.setPromoVideoURL()
    }
    this.startInfoscreen()
  },

  props: {
    infoscreen: Object,
    pages: Array,
    gym: Object,
    promo_video: Object,
    gym_logo_path: String,
    gym_cover_path: String,
    sponsor_image_path: Object,
    cloudfront_endpoint: String,
    vl_api_url: String,
    theme_color: String,
    gym_challenges: {
      type: Array,
      default: () => [],
    },
  },

  data: function () {
    return {
      activePageIndex: '',
      activePage: '',
      showGymVideo: false,
      totalGymPromoTime: 0,
      resetSinglePage: false,
      promoVideoUrl: '',
      checkVideoAvailability: true,
    }
  },

  computed: {
    promoVideoPresent() {
      return this.promoVideoUrl != ''
    },
  },

  methods: {
    startInfoscreen: function () {
      this.activePageIndex = 0
      this.activePage = this.pages[this.activePageIndex]
    },

    nextPage: function () {
      if (this.pages.length == 1) {
        this.resetSinglePage = true
        setTimeout(() => {
          this.resetSinglePage = false
        }, 1)
      } else {
        this.activePageIndex++
        if (this.activePageIndex >= this.pages.length) {
          this.startInfoscreen()
        }
        this.activePage = this.pages[this.activePageIndex]
      }
    },

    isActive: function (id) {
      if (this.pages[this.activePageIndex]) {
        return this.pages[this.activePageIndex].id === id
      } else {
        return false
      }
    },

    showSidebar() {
      return this.infoscreen.sidebar === 'show' && this.activePage && this.activePage.template != 'promotion'
    },

    showPadding() {
      return this.activePage && this.activePage.template != 'promotion'
    },

    showGymPromoVideo() {
      this.showGymVideo = true
      this.$refs.videoPlayer.play()
    },

    onPromoVideoEnd() {
      this.showGymVideo = false
      this.totalGymPromoTime = 0
      this.nextPage()
    },

    async promoVideoAvailable() {
      try {
        const { status } = await axios.head(this.promoVideoUrl)
        const isAvailable = status == 200 || status == 400
        this.checkVideoAvailability = !isAvailable
        return isAvailable
      } catch (e) {
        return false
      }
    },

    setPromoVideoURL() {
      const media_id = this.promo_video.url.match(/manifests\/(\w+)\./)[1]
      const template_id = 29896754 // depends on video resolution 29896754 = 1080x1920
      const media_extension = '.mp4'

      this.promoVideoUrl = 'https://cdn.jwplayer.com/videos/' + media_id + '-' + template_id + media_extension
    },
  },

  components: {
    gym_info: GymInfo,
    Rankings,
    Routes,
    Boulders,
    Promotion,
    Zlagfeed,
    Navigation,
    Challenges,
    gym_challenges: GymChallenges,
  },
}
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
