<template>
  <td :id="'route-cell-' + route.id" class="topo-cell">
    <svg :width="image_width" :height="image_height + vertical_adjustment * 2">
      <rect x="0" y="0" :width="image_width" :height="image_height + vertical_adjustment * 2" fill="#fff" />
      <image x="0" :y="vertical_adjustment" :width="image_width" :height="image_height" :xlink:href="fullImageUrl" opacity="0.8" />
      <template>
        <template>
          <transition :key="route.id + ':route'" name="fade">
            <polyline :points="getPointsForPath(route.path)" :stroke="route.color_1" stroke-width="2.5" fill="none"></polyline>
          </transition>
          <transition v-if="route.color_2" :key="route.id + ':route'" name="fade">
            <polyline :points="getPointsForPath(route.path)" :stroke="route.color_2" stroke-width="2.5" stroke-dasharray="20,20" fill="none"></polyline>
          </transition>
        </template>
      </template>
    </svg>
  </td>
</template>

<script>
export default {
  created() {
    this.img = new Image()
    let self = this
    this.img.onload = function () {
      self.original_image_width = this.width
      self.original_image_height = this.height
    }
    this.img.src = this.fullImageUrl
    if (window.innerWidth > 1400) this.scaling_factor = 0.5
  },

  props: {
    cloudfront_endpoint: String,
    route: Object,
  },

  data: function () {
    return {
      original_image_width: 0,
      original_image_height: 0,
      vertical_adjustment: 20,
      scaling_factor: 0.4,
      img: null,
    }
  },

  computed: {
    fullImageUrl() {
      return `${this.cloudfront_endpoint}${this.route.image_path}.jpg`
    },

    image_width() {
      return this.original_image_width * this.scaling_factor
    },

    image_height() {
      return this.original_image_height * this.scaling_factor
    },
  },

  methods: {
    getPointsForPath(path) {
      path = JSON.parse(path).map(point => {
        return {
          x: point[0],
          y: point[1],
        }
      })
      let points = ''
      path.forEach(point => {
        points += '' + point.x * this.scaling_factor + ',' + (point.y * this.scaling_factor + this.vertical_adjustment) + ' '
      })
      return points
    },
  },
}
</script>
