<template>
  <div class="row">
    <div v-if="showStyle('a')" class="col-4 type-center mb-4">
      <img class="style-image" src="@scg/../images/athletic_inverted.png" alt />
      <h3 class="type-display-8">{{ getStyleText('a') }}</h3>
    </div>
    <div v-if="showStyle('c')" class="col-4 type-center mb-4">
      <img class="style-image" src="@scg/../images/cruxes_inverted.png" alt />
      <h3 class="type-display-8">{{ getStyleText('c') }}</h3>
    </div>
    <div v-if="showStyle('e')" class="col-4 type-center mb-4">
      <img class="style-image" src="@scg/../images/endurance_inverted.png" alt />
      <h3 class="type-display-8">{{ getStyleText('e') }}</h3>
    </div>
    <div v-if="showStyle('f')" class="col-4 type-center mb-4">
      <img class="style-image" src="@scg/../images/finger_strength_inverted.png" alt />
      <h3 class="type-display-8">{{ getStyleText('f') }}</h3>
    </div>
    <div v-if="showStyle('s')" class="col-4 type-center mb-4">
      <img class="style-image" src="@scg/../images/slopers_inverted.png" alt />
      <h3 class="type-display-8">{{ getStyleText('s') }}</h3>
    </div>
    <div v-if="showStyle('t')" class="col-4 type-center mb-4">
      <img class="style-image" src="@scg/../images/technical_inverted.png" alt />
      <h3 class="type-display-8">{{ getStyleText('t') }}</h3>
    </div>
  </div>
</template>

<script>
const I18N_KEYS = {
  t: 'technical',
  f: 'fingerstrength',
  a: 'athletic',
  e: 'endurance',
  c: 'cruxes',
  s: 'slopers',
}

export default {
  props: {
    zlaggable: Object,
  },

  computed: {
    styles() {
      let orig_styles = this.zlaggable.style
      return orig_styles && orig_styles.length > 3 ? orig_styles.substring(0, 3) : orig_styles
    },
  },

  methods: {
    showStyle(style) {
      if (this.styles) {
        return this.styles.indexOf(style) > -1
      } else {
        return false
      }
    },

    getStyleText(style) {
      const key = I18N_KEYS[style]
      if (!key) return '-'
      return this.$i18n.t(`routesetting.${key}`)
    },
  },
}
</script>
