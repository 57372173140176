<template>
  <div class="container-fluid page fullscreen p-0" :style="styleObject"></div>
</template>

<script>
export default {
  watch: {
    active: function (isActive) {
      if (isActive) {
        this.startPage()
      }
    },

    reset: function (reset) {
      if (reset) {
        this.startPage()
      }
    },
  },

  props: ['templateSettings', 'id', 'active', 'gym', 'infoscreen', 'logo', 'cover', 'cloudfront_endpoint', 'image', 'reset'],

  data: function () {
    return {
      styleObject: {
        backgroundImage: `url(${this.imageUrl()})`,
        height: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundColor: '#222222',
      },
    }
  },

  methods: {
    startPage: function () {
      if (this.image != null && this.image !== '') {
        setTimeout(this.finishedPage, this.templateSettings.duration * 1000)
      } else {
        this.$parent.$emit('finishedPage', 0)
      }
    },

    imageUrl() {
      if (this.image && this.image.path && this.image.path !== '') {
        return `${this.cloudfront_endpoint}${this.image.path}.jpg`
      } else {
        return ''
      }
    },

    finishedPage: function () {
      this.$parent.$emit('finishedPage', this.templateSettings.duration)
    },
  },
}
</script>
