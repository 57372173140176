import Vue from 'vue'

import '@core/../helpers/cssHelper'
import { create_pinia, PiniaVuePlugin } from '@scg/entry_point/stores/create_scg'
import appsignal from '@core/../helpers/appsignal'
import DisplayController from '@scg/infoscreens_display/display_controller.vue'
import i18n from '@core/../helpers/localeHelper'
import VerticalLifePlugin from '@core/../plugins/VerticalLifePlugin'

Vue.use(PiniaVuePlugin)
Vue.use(VerticalLifePlugin)

document.addEventListener('DOMContentLoaded', async () => {
  const node = document.getElementById('infoscreen_container')
  const props = JSON.parse(node.getAttribute('data'))
  new Vue({
    i18n,
    pinia: await create_pinia(),
    render: h => h(DisplayController, { props }),
  }).$mount(node)
})
