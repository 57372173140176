<template>
  <div class="row pl-4 routes-overview">
    <div class="col-4 routes-summary">
      <div class="d-flex">
        <div>
          <span class="type-display-big-1">{{ boulders_count }}</span>
          <br />
          <span class="type-display-6">{{ $t('routesetting.boulders').toUpperCase() }}</span>
        </div>
        <template v-if="new_boulders_count > 0">
          <div class="count-divider"></div>
          <span class="type-display-big-1 new-boulders">{{ new_boulders_count }}<img class="new-badge" src="@core/../images/new_badge.png" /></span>
        </template>
      </div>
    </div>
    <div class="col-12">
      <div class="row justify-content-center">
        <div :class="[showSidebar ? 'col-11' : 'col-8']">
          <canvas :id="'boulders-chart-' + _uid"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  created: function () {
    this.getGradesBoulders()
    this.getNumberOfBoulders()
    this.getNumberOfNewBoulders()
  },

  props: ['gym', 'showSidebar', 'color'],

  data: function () {
    return {
      grades_boulders: [],
      boulders_count: 0,
      new_boulders_count: 0,
      grade_colors: this.color,
      default_color: this.color,
    }
  },

  methods: {
    getGradeColors: function () {
      if (this.gym.bouldering_grading_system === 'custom') {
        Vue.smcb_axios
          .get('/' + this.gym.slug + '/infoscreens/custom_grade_colors')
          .then(response => {
            if (response.data) {
              this.grade_colors = response.data.map(color => {
                if (color && color !== '' && color !== null && color[0] !== null && color[0] !== '') {
                  return color
                } else {
                  return [this.default_color, color[1]]
                }
              })
              this.mapLabels()
              this.drawChart()
            }
          })
          .catch(error => {
            console.error('VL: error loading custom grade colors', error)
          })
      }
    },

    getGradesBoulders: function () {
      Vue.smcb_axios
        .get('/' + this.gym.slug + '/infoscreens/grades_boulders')
        .then(response => {
          if (response.data) {
            this.grades_boulders = response.data
            if (this.gym.bouldering_grading_system === 'custom') {
              this.getGradeColors()
            } else {
              this.drawChart()
            }
          }
        })
        .catch(error => {
          console.error('VL: error loading grades and boulders', error)
        })
    },

    getNumberOfBoulders: function () {
      Vue.smcb_axios
        .get('/' + this.gym.slug + '/infoscreens/boulders_count')
        .then(response => {
          if (response.data) {
            this.boulders_count = response.data.count
          }
        })
        .catch(error => {
          console.error('VL: error loading boulders count', error)
        })
    },

    getNumberOfNewBoulders: function () {
      Vue.smcb_axios
        .get('/' + this.gym.slug + '/infoscreens/new_boulders_count')
        .then(response => {
          if (response.data) {
            this.new_boulders_count = response.data.count
          }
        })
        .catch(error => {
          console.error('VL: error loading new boulders count', error)
        })
    },

    drawChart: function () {
      let ctxt = $('#boulders-chart-' + this._uid)
        .get(0)
        .getContext('2d')
      let backgroundColor = this.gym.bouldering_grading_system === 'custom' ? this.grade_colors.map(a => a[0]) : this.grade_colors
      var myChart = new Chart(ctxt, {
        type: 'bar',
        data: {
          datasets: [
            {
              data: this.grades_boulders.map(a => a.count),
              backgroundColor: backgroundColor,
              datalabels: {
                anchor: 'end',
                align: 'top',
                font: {
                  size: 20,
                },
              },
            },
          ],

          labels: this.grades_boulders.map(a => a.difficulty),
        },

        options: {
          layout: {
            padding: {
              top: 30,
            },
          },

          scales: {
            xAxes: [
              {
                ticks: {
                  autoSkip: false,
                  fontFamily: 'Glober',
                  fontSize: 20,
                },

                gridLines: {
                  display: false,
                  drawBorder: false,
                },
              },
            ],

            yAxes: [
              {
                display: false,
                gridLines: {
                  display: false,
                },

                ticks: {
                  min: 0,
                },
              },
            ],
          },

          responsive: true,
          legend: {
            display: false,
          },
        },
      })
    },

    mapLabels: function () {
      let sortedLabels = []
      for (let i = 0; i < this.grade_colors.length; i++) {
        let label = this.grades_boulders.find(x => x.difficulty === this.grade_colors[i][1])
        if (label) {
          sortedLabels.push(label)
        }
      }
      this.grades_boulders = sortedLabels
    },
  },
}
</script>
