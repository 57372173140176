<template>
  <div class="row pl-4">
    <div class="col-12 pl-2">
      <h2 class="type-display-4 mb-5">{{ challenge.title }}</h2>
      <div class="row">
        <div class="col-5 challenge-details pl-0 pr-0 ml-3">
          <img :src="getImageUrl(challenge.cover)" alt="" />
          <div class="row justify-content-center pt-2">
            <div class="col-12">
              <h3 class="type-display-5 type-center">{{ challenge.title.toUpperCase() }}</h3>
              <h3 class="type-display-6 type-center">{{ challenge.value_target }} {{ challenge.value_unit }}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-4 text-center">
              <img src="@scg/../images/challenges/participants.png" alt="" />
              <p class="type-display-5 type-center mb-0">{{ challenge.participants }}</p>
              <p class="type-display-8 type-center">{{ $t('infoscreens.participants').toUpperCase() }}</p>
            </div>
            <div class="col-4 text-center">
              <img src="@scg/../images/challenges/meters_collected.png" alt="" />
              <p class="type-display-5 type-center mb-0">{{ challenge.value }}</p>
              <p class="type-display-8 type-center">{{ $t(`infoscreens.${challenge.value_unit}_climbed`).toUpperCase() }}</p>
            </div>
            <div class="col-4 text-center">
              <img src="@scg/../images/challenges/date.png" alt="" />
              <p class="type-display-5 type-center mb-0">{{ daysLeft }}</p>
              <p class="type-display-8 type-center">{{ $t('infoscreens.days_left').toUpperCase() }}</p>
            </div>
          </div>
        </div>
        <div class="col-6 pl-5">
          <img src="@scg/../images/challenges/challenge.jpg" alt="" />
          <h2 v-if="prizes.length > 0" class="type-display-6 mb-3 mt-3">{{ $t('infoscreens.prizes').toUpperCase() }}</h2>
          <img v-for="(prize, index) in prizes" :key="index" class="mr-3" :src="getPriceIconUrl(prize.icon)" alt="" width="90" height="90" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import axios from 'axios'

export default {
  created() {
    this.getChallengeDetails()
  },

  props: ['challenge', 'cloudfront_endpoint', 'vl_api_url'],

  data: function () {
    return {
      prizes: [],
    }
  },

  computed: {
    daysLeft() {
      let diff = DateTime.fromISO(this.challenge.expiration_date.substring(0, 10)).diff(DateTime.local(), 'days')
      return Math.floor(diff.toObject().days)
    },
  },

  methods: {
    getImageUrl: function (image) {
      return `${this.cloudfront_endpoint}${image}.jpg`
    },

    getPriceIconUrl: function (icon) {
      return `${this.cloudfront_endpoint}${icon}`
    },

    getChallengeDetails: function () {
      axios
        .get(`${this.vl_api_url}/scg/challenges/${this.challenge.id}/prizes`)
        .then(response => {
          if (response.data) {
            let prizes = response.data
            if (prizes.length > 6) {
              // get 6 random prizes from the entire array
              this.prizes = this.getSortedPrizes(prizes.sort(() => 0.5 - Math.random()).slice(0, 6))
            } else {
              this.prizes = this.getSortedPrizes(prizes)
            }
          }
        })
        .catch(error => {
          console.error('VL: error loading challenges', error)
        })
    },

    getSortedPrizes: function (prizes) {
      return prizes.sort(function (a, b) {
        let compare = 0
        compare = a.ordering > b.ordering ? 1 : -1
        return compare
      })
    },
  },
}
</script>
