<template>
  <div>
    <page-header :title="getRankingTitle()" :show-sidebar="showSidebar()" :logo="logo" />
    <div v-if="!settings.combined" class="gender-selection">
      <transition name="buttons" mode="out-in">
        <button v-if="active_gender === 'm'" id="male" key="male-active" class="gender-selection-item active">
          {{ $t('infoscreens.male').toUpperCase() }}
        </button>
        <button v-else id="male" key="male-inactive" class="gender-selection-item">{{ $t('infoscreens.male').toUpperCase() }}</button>
      </transition>
      <transition name="buttons" mode="out-in">
        <button v-if="active_gender === 'f'" id="female" key="female-active" class="gender-selection-item active">
          {{ $t('infoscreens.female').toUpperCase() }}
        </button>
        <button v-else id="female" key="female-inactive" class="gender-selection-item">{{ $t('infoscreens.female').toUpperCase() }}</button>
      </transition>
    </div>
    <table class="ranking-table" :class="zlaggableType">
      <thead>
        <tr class="ranking-table-header">
          <th>{{ $t('infoscreens.rank').toUpperCase() }}</th>
          <th>{{ $t('infoscreens.name').toUpperCase() }}</th>
          <th>{{ $t('infoscreens.location').toUpperCase() }}</th>
          <th>{{ $t('infoscreens.points').toUpperCase() }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in ranking" :key="user.id">
          <td>{{ user.rank }}</td>
          <td>
            <span :class="[index === 0 ? 'king' : '']"><img :src="getAvatarUrl(user)" width="64" height="64" /></span>{{ user.name }}
          </td>
          <td>{{ user.country }}</td>
          <td>{{ user.score }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="ranking-table-footer">
          <td colspan="4">
            {{ $t('infoscreens.ranking_parameters').toUpperCase() }}
            <img src="@scg/../images/nav/rankings_white.png" alt="" width="39" height="39" />
            {{ settings.ascents }}
            {{ $t('infoscreens.best_ascents').toUpperCase() }}
            <img src="@core/../images/calendar_white.png" alt="" width="39" height="39" />
            {{ getTimespanAsText().toUpperCase() }}
            <template v-if="settings.combined">
              <img src="@core/../images/combined_white.png" alt="" width="39" height="39" />
              {{ $t('infoscreens.combined').toUpperCase() }}
            </template>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import axios from 'axios'

import PageHeader from '../page_header.vue'

export default {
  created: function () {},

  watch: {
    active: function (isActive) {
      if (isActive) {
        this.startRanking()
      }
    },
  },

  props: ['templateSettings', 'settings', 'gym', 'infoscreen', 'active', 'zlaggableType', 'logo'],

  data: function () {
    return {
      ranking: [],
      active_gender: 'm',
      total_duration: 0,
      scrollCounter: 1,
      scrollPages: 0,
      scrollInterval: 0,
      interval: {},
    }
  },

  methods: {
    startRanking: function () {
      $('.ranking-table.' + this.zlaggableType + ' tbody').animate(
        {
          scrollTop: 0,
        },
        100
      )
      this.getRanking()
    },

    scrollResults: function () {
      $('.ranking-table.' + this.zlaggableType + ' tbody').animate(
        {
          scrollTop: 693 * this.scrollCounter,
        },
        900
      )
      this.scrollCounter += 1
      if (this.scrollCounter >= this.scrollPages + 1) {
        clearInterval(this.interval)
        this.total_duration = this.scrollPages * this.scrollInterval
        if (this.settings.combined || this.active_gender == 'f') {
          this.finishedPage()
        } else if (this.active_gender == 'm') {
          this.active_gender = 'f'
          this.scrollCounter = 1
          this.scrollPages = 0
          this.startRanking()
        }
      }
    },

    getRanking: function () {
      let data
      if (this.settings.combined) {
        data = {
          params: {
            time_span: this.settings.span,
            limit_zlaggables: this.settings.ascents,
            limit: 45,
            zlaggable_filter: this.zlaggableType == 'GymRoute' ? 'gym_sportclimbing' : 'gym_bouldering',
            min_rank: 0,
            item_id: this.settings.gyms[0],
            item_type: 'gym',
          },
        }
      } else {
        data = {
          params: {
            time_span: this.settings.span,
            limit_zlaggables: this.settings.ascents,
            limit: 45,
            zlaggable_filter: this.zlaggableType == 'GymRoute' ? 'gym_sportclimbing' : 'gym_bouldering',
            min_rank: 0,
            item_id: this.settings.gyms[0],
            item_type: 'gym',
            gender: this.active_gender,
          },
        }
      }
      axios
        .get('https://vlcapi.vertical-life.info/rankings', data)
        .then(
          response => {
            if (response.data) {
              this.ranking = response.data
              this.scrollPages = Math.ceil(this.ranking.length / 9)
              this.scrollInterval = this.templateSettings.duration
              this.interval = setInterval(this.scrollResults, this.scrollInterval * 1000)
            }
          },
          response => {
            this.finishedPage()
          }
        )
        .catch(error => {
          console.error('VL: error loading rankings', error)
        })
    },

    finishedPage: function () {
      this.active_gender = 'm'
      this.scrollCounter = 1
      this.scrollPages = 0
      let duration = this.total_duration
      this.total_duration = 0
      $('.ranking-table tbody').animate(
        {
          scrollTop: 0,
        },
        100
      )
      this.$parent.$emit('finishedRanking', this.zlaggableType, duration)
    },

    showSidebar() {
      return this.infoscreen.sidebar === 'show'
    },

    getAvatarUrl(user) {
      if (user.avatar) {
        return 'https://d1ffqbcmevre4q.cloudfront.net/' + user.avatar
      } else {
        return 'https://d1ffqbcmevre4q.cloudfront.net/avatar_default_female_1.jpg'
      }
    },

    getTimespanAsText() {
      let text = ''
      switch (parseInt(this.settings.span)) {
        case 0:
          text = this.$i18n.t('infoscreens.all_time')
          break
        case 1:
          text = this.$i18n.t('infoscreens.today')
          break
        case 7:
          text = this.$i18n.t('infoscreens.last_week')
          break
        case 31:
          text = this.$i18n.t('infoscreens.last_month')
          break
        case 365:
          text = this.$i18n.t('infoscreens.last_year')
          break
      }
      return text
    },

    getRankingTitle() {
      if (this.zlaggableType == 'GymRoute') {
        return this.$i18n.t('infoscreens.routes_ranking').toUpperCase()
      } else {
        return this.$i18n.t('infoscreens.boulders_ranking').toUpperCase()
      }
    },
  },

  components: {
    'page-header': PageHeader,
  },
}
</script>

<style>
.buttons-enter-active {
  transition: all 0.5s;
}
.buttons-enter,
.buttons-leave-to {
  opacity: 0.5;
}
</style>
